<template>
<!--  <MainHeader headline='Trainerlizenz' text='Erfassen Sie Ihre Trainerlizenz'/>-->

    <div class="card p-1 p-lg-4" v-if="licence && licence.verified ==='verified'">
      <div class="card-body">
        <h3 class="pb-3">Trainerlizenz</h3>
        <h5 class="card-title">Ihre Lizenz mit der Nummer <strong>{{licence.license_no}}</strong> wurde akzeptiert.</h5>
        <p class="card-text">Ihre Lizenz ist gültig bis <strong>{{$filters.formatDate(licence.valid_to)}}.</strong></p>
        <p style="text-align: center;" class="fa-4x"><i class="fa-regular fa-circle-check" style="color: green;"></i></p>
      </div>
    </div>
    <div class="card p-1 p-lg-4" v-if="licence && licence.verified === 'waiting'">
      <div class="card-body">
        <h3 class="pb-3">Trainerlizenz</h3>
        <h5 class="card-title">Ihre Lizenz wurde eingereicht und wird geprüft.</h5>
        <p class="card-text">Sobald Ihre Lizenz geprüft wurde, wird Ihnen das Ergebnis mitgeteilt.</p>
      </div>
    </div>

    <div class="card p-1 p-lg-4" v-if="licence && (licence.verified ==='denied' || licence.verified ==='')">
      <div class="card-body">
        <h3 class="pb-3">Trainerlizenz</h3>
        <div role="alert" class="alert alert-danger" v-if="licence.verified ==='denied'">
          <h3 style="text-align: center;">Lizenz abgelehnt!</h3>
        <p class="card-text" style="text-align: center;" >
          {{licence.verified_comment}}
        </p>
        <p style="text-align: center;" class="fa-4x"  ><i class="fa-regular fa-circle-exclamation" style="color: darkred;"></i></p></div>
<div class="row">
  <div class="col-12 col-lg-6"><div class="form-floating  mb-3">
    <select v-model="licence.license" id="sellik" class="form-select">
      <option selected value="">Lizenz wählen</option>
      <option value="C">C Lizenz</option>
      <option value="B">B Lizenz</option>
      <option value="B+">B+ Lizenz</option>
      <option value="A">A Lizenz</option>
      <option value="A+">A+ Lizenz</option>
      <option value="Basiscoach">Basiscoach Lizenz</option>
      <option value="Torwart A">Torwart A Lizenz</option>
      <option value="Torwart B">Torwart B Lizenz</option>
      <option value="PRO Lizenz">PRO Lizenz</option>
      <option value="Torwartleistungskurs">Torwart Leistungskurs</option>
    </select>
    <label for="sellic">Lizenz wählen</label>
  </div>
    <div class="form-floating mb-3">
      <input class="form-control" id="nextLic" type="date" v-model="licence.valid_from" placeholder="Lizenz gültig von">
      <label for="nextLic">Lizenz gültig von</label>
    </div>
    <div class="form-floating  mb-3">
      <input class="form-control" id="nextLiclk" type="date" v-model="licence.valid_to" placeholder="Lizenz gültig bis">
      <label for="nextLiclk">Lizenz gültig bis</label>
    </div>
    <div class="form-floating mb-3">
      <input class="form-control" type="date" v-model="licence.start_from" id="startFrom" placeholder="Start der Ausbildung">
      <label for="startFrom">Start der Ausbildung</label>
    </div>
    <div class="form-floating  mb-3">
      <input class="form-control" type="text" v-model="licence.license_no" id="licNo" placeholder="Lizenznummer">
      <label for="licNo">Lizenznummer</label>
    </div></div>
  <div class="col-12 col-lg-6">
    <h5 style="margin-top: 18px;"><template v-if="licence.license_card_front"><i class="fa-regular fa-check-circle" style="color: green;"></i>&nbsp;</template>Vorderseite Lizenz&shy;ausweis</h5>
    <UploadComponent :compact="true" v-model="licence.license_card_front"  v-if="licence.license_card_front === ''" />
    <template v-if="licence.license_card_front !== ''">
    <h5 style="margin-top: 48px;"><template v-if="licence.license_card_back"><i class="fa-regular fa-check-circle" style="color: green;"></i>&nbsp;</template>Rückseite Lizenz&shy;ausweis</h5>
    <UploadComponentBack :compact="true" v-model="licence.license_card_back" v-if="licence.license_card_back === ''" /></template>
    <!-- show only if all values are filled -->
    <template v-if="licence.license_card_back !== '' && licence.license_card_front !=='' && licence.license_no !== '' && licence.valid_to !== '' && licence.license !== ''">
      <template v-if="loading">
        <button type="button" class="btn btn-primary" style="margin-top: 39px;" disabled><i class="fa-regular fa-spinner fa-spin"></i> Speichern</button>
      </template>
      <template v-else>


      <button type="button" class="btn btn-primary" style="margin-top: 39px;" @click="submitToServer()">Speichern</button>
      </template>
    </template>
    <template v-else>

      <button type="button" class="btn btn-primary"  style="margin-top: 155px" disabled >Speichern nach Upload möglich</button>
    </template>
  </div>
</div>

      </div>
    </div>

</template>
<script>
import MainHeader from "@/components/MainHeader";
import UploadComponent from "@/components/UploadComponentTrainer.vue";
import UploadComponentBack from "@/components/UploadComponentTrainerBack.vue";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'TrainerlizenzView',
  components: {

    MainHeader, UploadComponent,UploadComponentBack
  },
  computed: {
    ...mapGetters(["getApiUrl"])
  },
  data() {
    return {
      licence: {
        license: '',
        valid_from: '',
        valid_to: '',
        start_from:'',
        license_no: '',
        license_card_front: '',
        license_card_back: '',
        verified: ''
      },
      loading: false
    }
  },
  methods: {
    submitToServer() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.post(this.getApiUrl + 'trainer/license'  , this.licence, {
        headers
      })
        .then(response => {
          this.getLicense();

        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getLicense(){
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'trainer/license'  , {
        headers
      })
        .then(response => {
          console.log(response);
          this.licence = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    this.getLicense();
  }
}
</script>
